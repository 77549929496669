<template>
  <div>
    Dashboard de vendas
    <v-divider />
    <v-row>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Marca
                </th>
                <th class="text-left">
                  Venda
                </th>
                <th class="text-left">
                  Lucro
                </th>
                <th class="text-left">
                  Margem
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in saleProductResult" :key="index">
                <td>{{ row.brand_name }}</td>
                <td class="text-right">{{ $format.decimal(row.net_total_sum) }}</td>
                <td class="text-right">{{ $format.decimal(row.profit_sum) }}</td>
                <td class="text-right">{{ $format.decimal(row.profit_sum / row.net_total_sum * 100) }}%</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    sales: [],
    saleProductResult: [],
    saleProductParams: {
      metrics: ["net_total_sum", 'profit_sum'],
      dimensions: ["product.brand.name"],
    },
  }),

  created() {
    this.select();
  },

  methods: {
    select() {
      this.$http
        .index("bi/sale-product", this.saleProductParams)
        .then((response) => {
          //this.saleProductResult = response.saleProduct;

          // sort by net_total_sum
          this.saleProductResult = response.saleProduct.sort((a, b) => {
            return b.profit_sum - a.profit_sum;
          });
        });
    },
  },
};
</script>

<style></style>
